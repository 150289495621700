<template>
  <div class="page-div">
    <div class="login-div">
      <div class="login-box-div">
        <div class="box-title-div">欢迎登录</div>
        <validation-observer ref="form">
          <div class="item-div">
            <validation-provider rules="required" v-slot="{ errors }" name="account">
              <label>
                <input type="text" v-model="acc" placeholder="请输入账号" />
              </label>
              <div class="tips-div text-c err-tips">{{ errors[0] }}</div>
            </validation-provider>
          </div>
          <div class="item-div">
            <validation-provider rules="required" v-slot="{ errors }" name="password">
              <label>
                <input type="password" v-model="pwd" placeholder="请输入密码" />
              </label>
              <div class="tips-div text-c err-tips">{{ errors[0] }}</div>
            </validation-provider>
          </div>
          <div class="code-div" v-if="isShowValidateCode">
            <div>
              <validation-provider rules="required" v-slot="{ errors }" name="code">
                <label>
                  <input type="text" v-model="code" placeholder="请输入验证码" />
                </label>
                <div class="tips-div text-c err-tips">{{ errors[0] }}</div>
              </validation-provider>
            </div>
            <div class="img-div">
              <img class="cursor-el" :src="validateCodeUrl" @click="refreshValidateCode" alt="" />
            </div>
          </div>
          <div class="bottom-div">
            <input type="button" value="登  录" :disabled="logging" @click="onLogin" />
          </div>
          <div class="login-tips-div text-c err-tips">{{ loginTips }}</div>
        </validation-observer>
      </div>
    </div>
  </div>
</template>

<script>
import md5 from "js-md5";
import { getValidateAcc, postLogin, verifyCodeImg, getAuthority } from "../common/api";

export default {
  name: "Login",
  data() {
    return {
      acc: "",
      pwd: "",
      code: "",
      isShowValidateCode: false,
      validateCodeUrl: null,
      loginTips: "",
      logging: false
    };
  },
  methods: {
    onLogin() {
      this.$refs.form.validate().then(res => {
        if (res) {
          this.logging = true;

          postLogin({
            acc: this.acc,
            pwd: this.$sraEncode(md5(this.pwd)),
            code: this.code
          }).then(data => {
            let user = { isLogin: true };
            user.acc = data.acc;
            user.name = data.name;
            user.phone = data.phone;
            user.token = data.token;
            this.$store.commit("setUser", user);
            this.initAuthority(user);
          }).catch(error => {
            this.logging = false;
            if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
              this.setErrorTipsMsg(error.data.msg);
              if (error.data.status === 1003) {
                this.isShowValidateCode = true;
              }
              if (this.isShowValidateCode) {
                this.refreshValidateCode();
              }
            } else {
              this.setErrorTipsMsg("登录失败，请重试");
            }
          });
        }
      });
    },
    refreshValidateCode() {
      this.validateCodeUrl = verifyCodeImg(this.acc, Math.random());
    },
    setErrorTipsMsg(msg) {
      this.loginTips = msg;
      clearTimeout(this.timeCounter);
      this.timeCounter = setTimeout(() => {
        this.loginTips = "";
      }, 3000);
    },
    initAuthority(user) {
      getAuthority().then(data => {
        user.authority = data;
        this.$store.commit("setUser", user);
        this.$router.push({ path: "/" });
      }).catch(error => {
        this.$store.commit("setUser", { isLogin: false });
        this.logging = false;
        if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
          this.setErrorTipsMsg(error.data.msg);
          if (this.isShowValidateCode) {
            this.refreshValidateCode();
          }
        } else {
          this.setErrorTipsMsg("登录失败，请重试");
        }
      });
    }
  },
  watch: {
    acc: function(newVal, oldVal) {
      if (/^[a-z][a-z_0-9]{4,17}$/.test(newVal)) {
        if (newVal !== oldVal) {
          getValidateAcc({ acc: this.acc }).then(data => {
            if (data === 1) {
              this.isShowValidateCode = false;
            } else {
              if (!this.isShowValidateCode) {
                this.isShowValidateCode = true;
                this.refreshValidateCode();
              }
            }
          });
        }
      } else {
        this.isShowValidateCode = false;
      }
    }
  }
};
</script>

<style lang="less" scoped>
.page-div {
  width: 100%;
  height: 100%;
  position: relative;
  .login-div {
    width: 820px;
    height: 560px;
    background: url("../../public/img/login-box.png") no-repeat;
    background-size: 100%;
    position: absolute;
    left: calc(50% - 410px);
    top: 20%;
    .login-box-div {
      width: 450px;
      height: 500px;
      margin-top: 30px;
      margin-left: 340px;
      .box-title-div {
        font-size: 24px;
        font-weight: bold;
        text-align: center;
        margin-top: 60px;
      }
      .item-div {
        text-align: center;
        input {
          width: 340px;
          height: 50px;
          font-size: 16px;
          border: none;
          background: #f3f8fd;
          border-radius: 5px;
          padding: 0 5px;
        }
      }
      .item-div:first-child {
        margin-top: 30px;
        margin-bottom: 15px;
      }
      .code-div {
        margin-top: 15px;
        display: flex;
        justify-content: center;
        input {
          width: 205px;
          height: 50px;
          font-size: 16px;
          border: none;
          background: #f3f8fd;
          border-radius: 5px;
          padding: 0 5px;
        }
        .img-div {
          width: 130px;
          height: 50px;
          background: #f3f8fd;
          margin-left: 5px;
          img {
            width: 100%;
            height: 100%;
            border-radius: 5px;
          }
        }
      }
      .bottom-div {
        text-align: center;
        margin-top: 20px;
        input {
          width: 350px;
          height: 40px;
          font-size: 18px;
          color: white;
          background: #446384;
          border: none;
          border-radius: 3px;
          padding: 0;
        }
      }
      .tips-div {
        width: 100%;
        height: 20px;
      }
      .login-tips-div {
        margin-top: 10px;
      }
    }
  }
}
</style>
